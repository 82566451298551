class ExtraLanguage {
    id;
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.important = data.important;
        this.requirements = data.requirements;
        this.language = data.language;
    }
}
var DataConverter = {
    toFirestore: function(item) {
        return {
            name: item.name,
            description: item.description,
            important: item.important,
            requirements: item.requirements,
            language: item.language,
        }
    },
    fromFirestore: function(snapshot, options) {
        const data = snapshot.data(options);
        data.id = snapshot.id
        return new ExtraLanguage(data)
    }
}
export { ExtraLanguage, DataConverter }