<template>
  <div>
    <portal to="title-page">
      <h1>Editar extra</h1>
    </portal>
    <v-card
      class="mx-auto elevation-1"
    >
      <v-card-title >
        <v-icon
          large
          left
        >
          {{mdiPencil}}
        </v-icon>
        <span class="title font-weight-light">Datos</span>
        <v-btn small @click="$router.push({path:`/backend/admin/extras/${$route.params.id}/images`})" color="success" class="ml-auto"><v-icon>{{mdiImageMultiple}}</v-icon> Imagenes</v-btn>
        <v-btn small @click.stop="dialogAddLang.show = true" color="primary" class="ml-2"><v-icon>{{mdiPlus}}</v-icon> lenguaje</v-btn>
        <v-btn small class="ml-2" @click="$router.go(-1)"><v-icon>{{mdiArrowLeft}}</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row >
            <v-col cols="12" md="12" class="py-0">
              <v-select
                v-if="categoriesExtras != null"
                v-model="category"
                :items="categoriesExtras"
                item-text="name"
                return-object
                :prepend-icon="mdiFilter"
                :rules="[v => !!v || 'La Categoria es requerida']"
                label="Seleccione la categoria"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" class="">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="12" class="">
              <label>Local</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.localAdult"
                :rules="[rules.required,rules.number]"
                :label="isATV() ? `Precio adulto single` : `Precio adulto`"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="isATV()" cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.localAdultShared"
                :rules="[rules.required,rules.number]"
                label="Precio adulto shared"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.localChild"
                :rules="[rules.required,rules.number]"
                label="Precio niño"
                prefix="$"
                required
                :prepend-icon="mdiHumanMaleChild"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.localElderly"
                :rules="[rules.required,rules.number]"
                label="Precio adulto mayor"
                prefix="$"
                :prepend-icon="mdiHumanCane"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.localInfant"
                :rules="[rules.required,rules.number]"
                label="Precio Infante"
                prefix="$"
                :prepend-icon="mdiBabyCarriage"
                required
              ></v-text-field> 
            </v-col>-->
            <v-col cols="12" md="12" class="">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="12" class="">
              <label>Nacional</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.nationalAdult"
                :rules="[rules.required,rules.number]"
                :label="isATV() ? `Precio adulto single` : `Precio adulto`"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="isATV()" cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.nationalAdultShared"
                :rules="[rules.required,rules.number]"
                label="Precio adulto shared"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.nationalChild"
                :rules="[rules.required,rules.number]"
                label="Precio niño"
                prefix="$"
                required
                :prepend-icon="mdiHumanMaleChild"
              ></v-text-field>
            </v-col>
            <!-- -->
            <v-col cols="12" md="12" class="">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="12" class="">
              <label>Extranjero</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.foreignAdult"
                :rules="[rules.required,rules.number]"
                :label="isATV() ? `Precio adulto single` : `Precio adulto`"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="isATV()" cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.foreignAdultShared"
                :rules="[rules.required,rules.number]"
                label="Precio adulto shared"
                prefix="$"
                :prepend-icon="mdiHumanMale"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extra.foreignChild"
                :rules="[rules.required,rules.number]"
                label="Precio niño"
                prefix="$"
                required
                :prepend-icon="mdiHumanMaleChild"
              ></v-text-field>
            </v-col>
            <!--  -->
            <v-col cols="12" md="12" class="py-3">
              <!-- -->
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-select
                v-if="registerLanguages != null"
                v-model="language"
                :items="registerLanguages"
                item-text="language"
                return-object
                :prepend-icon="mdiFlag"
                :rules="[v => !!v || 'El idioma es requerido']"
                label="Seleccione el idioma"
                required
                @change="changeLang"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="extraLanguage.name"
                :rules="[rules.required]"
                label="Nombre"
                :prepend-icon="mdiText"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-combobox
                v-if="availablePanels != null"
                v-model="panels"
                :items="availablePanels"
                :prepend-icon="mdiText"
                label="Mostrar en:"
                multiple
                required
              >
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" class="py-0">
              <p >Descripcion</p>
              <vue-editor
                id="description"
                :class="extraLanguage.description != null && extraLanguage.description == '' ? 'error' : ''"
                v-model="extraLanguage.description"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <p >Importante</p>
              <vue-editor
                id="important"
                :class="extraLanguage.important != null && extraLanguage.important == '' ? 'error' : ''"
                v-model="extraLanguage.important"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <p >Requerimientos</p>
              <vue-editor
                id="requirements"
                :class="extraLanguage.requirements != null && extraLanguage.requirements == '' ? 'error' : ''"
                v-model="extraLanguage.requirements"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </v-col>
            <v-col cols="12" md="12" class="pt-5">
              <v-btn
                :disabled="disableBtnAdd"
                color="primary"
                class="mr-4"
                @click="validateForm"
              >
                <v-icon>mdi-content-save</v-icon>
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- -->
    </v-card>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="-1"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogAddLang.show"
      max-width="450"
    >
      <v-card>
        <v-card-title class="headline">Seleccione</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="formlang"
            v-model="validFormLang"
            lazy-validation
          >
            <v-select
              v-if="availableLanguages != null"
              v-model="newLang"
              :items="availableLanguages"
              item-text="name"
              return-object
              :prepend-icon="mdiFlag"
              :rules="[v => !!v || 'El idioma es requerido']"
              label="Seleccione el idioma"
              required
            ></v-select>
            <p>Una vez seleccionado el lenguaje haga click en agregar y cuando se cierre esta ventana edite los datos.</p>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="dialogAddLang.show = false"
          >
            Cerrar
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!validFormLang"
            @click="addLang"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Language from "@/models/Language.js";
import Extra from "@/models/Extra.js";
import CategoryExtra from "@/models/CategoryExtra.js";
import {DataConverter} from "@/FirestoreDataConverters/ExtraLanguage.js";
import { mdiHumanMaleChild, mdiBabyCarriage, mdiHumanMale, mdiClose, mdiArrowLeft, mdiFlag, mdiText, mdiFilter, mdiPlus, mdiPencil, mdiImageMultiple } from '@mdi/js';
import { mdiHumanCane  } from '@/assets/icons/mdi-icons-extended.js';
import { VueEditor } from "vue2-editor";

export default {
  name: "ExtraImages",
  components: { VueEditor },
  data() {
    return {
      mdiHumanMaleChild:mdiHumanMaleChild,
      mdiBabyCarriage:mdiBabyCarriage,
      mdiHumanCane:mdiHumanCane,
      mdiHumanMale:mdiHumanMale,
      mdiClose:mdiClose,
      mdiArrowLeft:mdiArrowLeft,
      mdiFlag:mdiFlag,
      mdiText:mdiText,
      mdiFilter:mdiFilter,
      mdiPlus:mdiPlus,
      mdiPencil:mdiPencil,
      mdiImageMultiple:mdiImageMultiple,
      ////////////////////////////////////////////////////////////////////////////
      languageModel:new Language,
      extraModel:new Extra,
      categoryExtraModel:new CategoryExtra,
      ////////////////////////////////////////////////////////////////////////////
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ],
      ////////////////////////////////////////////////////////////////////////////
      systemLanguages:null,
      registerLanguages:null,
      availableLanguages:null,
      categoriesExtras:null,
      availablePanels: [
        {text:'WebSite', value:'website'},
        {text:'Taquilla', value:'taquilla'},
        {text:'Buzos', value:'divers'}
      ],
      validForm: true,
      validFormLang: true,
      validFormInit: true,
      rules : {
        required : v => v && v.length > 0 || 'Campo requerido',
        number : v => {
          const pattern = /^[0-9]+([.][0-9]+)?$/
          return pattern.test(v) || 'Numero invalido'
        },
      },
      language:null,
      newLang:null,
      category:null,
      panels: [],
      selectedLanguageId:'es',
      extra:{
        name:'',
        adult:'',
        elderly:'',
        child:'',
        infant:'',
        category:{},
      },
      ref:null,
      unsubscribeLangs:null,
      extraLanguage:{
        description:null,
        requirements:null,
        important:null,
      },
      snackbar:{
        show:false,
        color:'',
        message:''
      },
      dialogAddLang:{
        show:false
      }
    }
  },
  methods:{
    validateForm() {
      this.validFormInit = false;
      this.extraLanguage.description = this.extraLanguage.description == null ? '' : this.extraLanguage.description;
      this.extraLanguage.important = this.extraLanguage.important == null ? '' : this.extraLanguage.important;
      this.extraLanguage.requirements = this.extraLanguage.requirements == null ? '' : this.extraLanguage.requirements;
      if (this.$refs.form.validate()) {
        let loading = this.$loading.show();
        let data = {
          localAdult:this.extra.localAdult,
          localChild:this.extra.localChild,
          //localElderly:this.extra.localElderly,
          //localInfant:this.extra.localInfant,
          nationalAdult:this.extra.nationalAdult,
          nationalChild:this.extra.nationalChild,
          //nationalElderly:this.extra.nationalElderly,
          //nationalInfant:this.extra.nationalInfant,
          foreignAdult:this.extra.foreignAdult,
          foreignChild:this.extra.foreignChild,
          panels:this.extra.panels,
          //foreignElderly:this.extra.foreignElderly,
          //foreignInfant:this.extra.foreignInfant,
          //status:this.extra.status,
          category:this.category.name,
          type: 'regular',
          categoryId:this.category.id,
        };
        if (this.extraLanguage.id == 'es') {
          data.name = this.extraLanguage.name;
        }
        if (this.isATV()) {
          data.type = 'atv'
          data.localAdultShared = this.extra.localAdultShared
          data.nationalAdultShared = this.extra.nationalAdultShared
          data.foreignAdultShared = this.extra.foreignAdultShared
        }///////////////////////////////////
        if(this.panels.length != 0){
          let values = {}
          for(const panelItem of this.panels) {
            values[panelItem.value] = panelItem.value
          }
          data.panels = values
        }///////////////////////////////////
        const dataExtraLang = {
          name:this.extraLanguage.name,
          description:this.extraLanguage.description,
          important:this.extraLanguage.important,
          requirements:this.extraLanguage.requirements,
          language:this.extraLanguage.language
        }
        this.ref.update(data).then(() => {
          this.ref.collection('extraLanguages').doc(this.extraLanguage.id).update(dataExtraLang)
          .then(() => {
            loading.hide();
            this.showSnackbarSuccess('El extra se actualizo correctamente');
          })
          .catch(error => {
            loading.hide();
            console.log(error);
            this.showSnackbarError('Error al actualizar el lenguaje del extra');
          })
        })
        .catch( error=> {
          console.log(error);
          loading.hide();
          this.showSnackbarError('Error al actualizar el extra');
        })
      }
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'green';
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = 'red';
    },
    setAvailableLanguages() {
      this.availableLanguages = [];
      if (this.systemLanguages !== null) {
        if (this.registerLanguages !== null) {
          for(var i in this.systemLanguages) {
            let langId = this.systemLanguages[i].id;
            let exist = this.registerLanguages.find(item => item.id == langId);
            if (!exist) {
              this.availableLanguages.push(this.systemLanguages[i]);
            }
          }
        } else {
          this.availableLanguages = this.systemLanguages;
        }
      }
    },

    addLang() {
      if (this.$refs.formlang.validate()) {
        let loading = this.$loading.show();
        if (this.unsubscribeLangs) {
          this.unsubscribeLangs();
        }
        this.ref.collection('extraLanguages').doc(this.newLang.id).set({
          description:'',
          requirements:'',
          important:'',
          language:this.newLang.name,
          name:''
        }).then(() => {
          this.getLangs();
          this.selectedLanguageId = this.newLang.id;
          loading.hide();
          this.showSnackbarSuccess('El lenguaje se agrego correctamente, ingrese la informacion correspendiente');
          this.dialogAddLang.show = false;
        });
      }
    },
    getLangs() {
      this.unsubscribeLangs = this.ref.collection('extraLanguages').withConverter(DataConverter).onSnapshot(docs => {
        this.registerLanguages = []
        var index, i = 0;
        docs.forEach(doc => {
          this.registerLanguages.push(doc.data());
          if (doc.data().id == this.selectedLanguageId) {
            index = i;
          }
          i++;
        })
        this.language = this.registerLanguages[index];
        this.extraLanguage = this.registerLanguages[index];
        this.setAvailableLanguages();
      })
    },
    changeLang() {
      this.selectedLanguageId = this.language.id;
      this.extraLanguage = this.language;
    },
    isATV() {
      return this.category && this.category.id == '35GgLDUefBqgKGSqiMhv'
    }
  },
  created () {
    this.$store.commit('SET_LAYOUT', 'admin')
  },
  computed:{
    disableBtnAdd : function() {
      if (this.validFormInit) {
        return false;
      }
      if (!this.extraLanguage.description || !this.extraLanguage.important || !this.extraLanguage.requirements || !this.validForm) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.languageModel.getAll().onSnapshot(snap => {
      this.systemLanguages = [];
      snap.forEach( doc => {
        this.systemLanguages.push(doc.data());
      })
      this.setAvailableLanguages();
    })
    this.categoryExtraModel.getAll().onSnapshot(snap => {
      this.categoriesExtras = [];
      snap.forEach(doc => {
        this.categoriesExtras.push(doc.data());
      })
    })
    this.extraModel.findById(this.$route.params.id).onSnapshot(doc => {
      if (doc.exists) {
        this.extra = {
          localAdult:doc.data().localAdult,
          localChild:doc.data().localChild,
          localAdultShared:doc.data().localAdultShared,
          //localElderly:doc.data().localElderly,
          //localInfant:doc.data().localInfant,
          nationalAdult:doc.data().nationalAdult,
          nationalChild:doc.data().nationalChild,
          nationalAdultShared:doc.data().nationalAdultShared,
          //nationalElderly:doc.data().nationalElderly,
          //nationalInfant:doc.data().nationalInfant,
          foreignAdult:doc.data().foreignAdult,
          foreignChild:doc.data().foreignChild,
          foreignAdultShared:doc.data().foreignAdultShared,
          //foreignElderly:doc.data().foreignElderly,
          //foreignInfant:doc.data().foreignInfant,
          name:doc.data().name,
          status:doc.data().status,
          panels: doc.data().panels
        }
        this.panels = []
        const panelsText = {
          website: 'Website',
          taquilla: 'Taquilla',
          divers: 'Buzos',
        }
        for(const i in this.extra.panels) {
          this.panels.push({text: panelsText[i], value: this.extra.panels[i]})
        }
        this.ref = doc.ref;
        this.category = {
          id:doc.data().categoryId,
          name:doc.data().category,
        }
        if (this.unsubscribeLangs) {
          this.unsubscribeLangs();
        }
        this.getLangs();
      }
    })
  },
}
</script>
<style>
  #app .quillWrapper.error {
    background-color: transparent !important;
  }
  .quillWrapper.error > div {
    border-color: #ff5252;
    background-color: transparent;
  }
</style>
